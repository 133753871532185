import { Theme, getCustomTheme, valiotTheme } from '@valiot/core'

const customTheme = {
  ...valiotTheme,
  customColors: {
    Items: {
      340047: ' #1890ff',
      315437: ' #1890ff',
      343523: ' #1890ff',
      343292: ' #1890ff',
      342755: ' #1890ff',
      327602: ' #1890ff',
      344141: ' #1890ff',
      343515: ' #1890ff',
      342158: ' #1890ff',
      310755: ' #1890ff',
      345212: ' #1890ff',
      329208: ' #1890ff',
      329010: ' #1890ff',
      310575: ' #1890ff',
      319017: ' #1890ff',
      344840: ' #1890ff',
      336321: ' #1890ff',
      342256: ' #1890ff',
      343447: ' #1890ff',
      334094: ' #1890ff',
      321808: ' #1890ff',
      319493: ' #1890ff',
      341537: ' #1890ff',
      342171: ' #1890ff',
      333618: ' #1890ff',
      339779: ' #1890ff',
      345884: ' #1890ff',
      343507: ' #1890ff',
      331694: ' #1890ff',
      334459: ' #1890ff',
      325501: ' #1890ff',
      327983: ' #1890ff',
      343518: ' #1890ff',
      328484: ' #1890ff',
      318949: ' #1890ff',
      326171: ' #1890ff',
      326173: ' #1890ff',
      342335: ' #1890ff',
      325760: ' #1890ff',
      326197: ' #1890ff',
      325763: ' #1890ff',
      341842: ' #1890ff',
      329635: ' #1890ff',
      323075: ' #1890ff',
      342251: ' #1890ff',
      334607: ' #1890ff',
      338859: ' #1890ff',
      333957: ' #1890ff',
      327614: ' #1890ff',
      333617: ' #1890ff',
      345548: ' #1890ff',
      334517: ' #1890ff',
      343291: ' #1890ff',
      343529: ' #1890ff',
      343285: ' #1890ff',
      341533: ' #1890ff',
      343486: ' #1890ff',
      319890: ' #1890ff',
      343488: ' #1890ff',
      336936: ' #1890ff',
      338399: ' #1890ff',
      340246: ' #1890ff',
      343286: ' #1890ff',
      343289: ' #1890ff',
      328485: ' #1890ff',
      342277: ' #1890ff',
      328482: ' #1890ff',
      343448: ' #1890ff',
      328483: ' #1890ff',
      344525: ' #1890ff',
      310756: ' #1890ff',
    },
    stops: {
      stop: '#ff4d4f',
    },
  },
}

export const appTheme: Theme = getCustomTheme({
  // you can set a default theme as a base for your new theme (defaults to "valiotTheme"):
  baseTheme: customTheme,
  overrides: {
    // update this object to setup a custom theme for the app
    colors: {
      brand: {
        // base: '#FF0000',
        // shade: '#FF2020'
      },
    },
  },
})
